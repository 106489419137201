import { mapHelper } from "@/utils/common.js";

const status = [
  {
    value: 1,
    label: "未开始",
  },
  {
    value: 2,
    label: "进行中",
  },
  {
    value: 3,
    label: "已结束",
  },
];

const { map: statusMap, setOps: setStatusOps } = mapHelper.setMap(status);

const awTimes = [
  // {
  //   value: 0,
  //   label: "不限定",
  // },
  {
    value: 1,
    label: "仅可以一次",
  },
  {
    value: 2,
    label: "一天一次",
  },
];

const { map: awTimesMap, setOps: setAwTimesOps } = mapHelper.setMap(awTimes);

const shelfSts = [
  {
    value: 0,
    label: "待发布",
  },
  {
    value: 1,
    label: "已发布",
  },
  {
    value: 2,
    label: "已下架",
  },
];
const { map: shelfStsMap, setOps: setShelfStsOps } = mapHelper.setMap(shelfSts);

const userType = [
  {
    value: "4",
    checked: false,
    label: "居民",
  },
  {
    value: "5",
    checked: false,
    label: "企业/商户",
  },
  // {
  //   value: "21",
  //   checked: false,
  //   label: "运营人员",
  // },
];

const { map: userTypeMap, setOps: setUserTypeOps } = mapHelper.setMap(userType);

const isOnlyHouseOwnerOps = [
  {
    name: 0,
    value: "全部居民",
  },
  {
    name: 1,
    value: "业主",
  },
];

// const { map: isOnlyHouseOwnerMap, setOps: setIsOnlyHouseOwnerOps } =
//   mapHelper.setMap(isOnlyHouseOwner);

export {
  statusMap,
  setStatusOps,
  shelfStsMap,
  setShelfStsOps,
  userTypeMap,
  setUserTypeOps,
  setAwTimesOps,
  awTimesMap,
  isOnlyHouseOwnerOps,
};
